import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DialogActionsStyled from "../../helpersComponents/PopupHelpers/dialogActionsStyled";
import DialogStyled from "../../helpersComponents/PopupHelpers/dialogStyled";
import { DialogContentStyled } from "../../helpersComponents/PopupHelpers/dialogContentStyled";
import DialogTitleStyled from "../../helpersComponents/PopupHelpers/dialogTitleStyled";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ApproveRouletteEventPopupContent from "./ApproveRouletteEventPopupContent";
import { useForm } from "react-hook-form";
import useFetchOtherThanGET from "../../../hooks/fetchHooks/useFetchOtherThanGET/useFetchOtherThanGET";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import { RouletteEventOptionType } from "../../../enums/RouletteEventOptionType";

const ApproveRouletteEventPopup: React.FC<any> = ({
  isOpen,
  rowId,
  closePopup,
  successCallback,
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [alertId, setAlertId] = useState<number | null>(null);

  const successCallbackAndClose = useCallback(() => {
    if (successCallback) {
      successCallback();
    }

    closePopup();
  }, [successCallback, closePopup]);

  const schema = Yup.object({
    selectedOption: Yup.number()
      .required(t("field_required"))
      .positive()
      .integer(),
    note: Yup.string().nullable(),
  });

  const [
    rouletteAlertFromQueue,
    fetchingStateRouletteAlertFromQueue,
    fetchAgainRouletteAlertFromQueue,
  ] = useFetchAndSetGET({
    path: `personal-control/roulette-alerts-queue`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (
      !fetchingStateRouletteAlertFromQueue?.isFetching &&
      !fetchingStateRouletteAlertFromQueue?.isError
    ) {
      if (
        fetchingStateRouletteAlertFromQueue?.response?.status === 200 &&
        rouletteAlertFromQueue?.alertId
      ) {
        setAlertId(rouletteAlertFromQueue.alertId);
        setIsFetching(false);
      } else if (
        fetchingStateRouletteAlertFromQueue?.response?.status === 204
      ) {
        successCallbackAndClose();
      }
    }
  }, [
    fetchingStateRouletteAlertFromQueue?.isFetching,
    fetchingStateRouletteAlertFromQueue?.isError,
    fetchingStateRouletteAlertFromQueue?.response?.status,
    rouletteAlertFromQueue?.alertId,
    successCallbackAndClose,
  ]);

  const [
    rouletteAlertDetail,
    fetchingStateRouletteAlertDetail,
    fetchAgainRouletteAlertDetail,
  ] = useFetchAndSetGET({
    path: `personal-control/roulette-alerts-details?alertId=${alertId}`,
    startFetchOnInitial: false,
  });

  useEffect(() => {
    if (isOpen && rowId) {
      setAlertId(rowId);
    }
  }, [isOpen, rowId]);

  useEffect(() => {
    if (alertId) {
      fetchAgainRouletteAlertDetail();
    }
  }, [alertId, fetchAgainRouletteAlertDetail]);

  const [approveOmmitRequestBody, setApproveOmmitRequestBody] =
    useState<any>(null);

  let approveOmmitSuccessCallback = useCallback(
    (response: UseFetchResponse) => {
      fetchAgainRouletteAlertFromQueue();
    },
    [fetchAgainRouletteAlertFromQueue]
  );

  const [, fetchAgainAddRouletteAlertDetail] = useFetchOtherThanGET({
    path: `personal-control/roulette-alerts-details`,
    method: "POST",
    contentType: "application/json",
    body: approveOmmitRequestBody,
    setBody: setApproveOmmitRequestBody,
    successCallback: approveOmmitSuccessCallback,
    disableErrorSnackbar: true,
    disableSuccessSnackbar: false,
  });

  useEffect(() => {
    if (approveOmmitRequestBody) {
      fetchAgainAddRouletteAlertDetail();
    }
  }, [approveOmmitRequestBody, fetchAgainAddRouletteAlertDetail]);

  const { handleSubmit, reset, control, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      selectedOption: 1,
      note: "",
    },
  });

  const onFormSubmit = useCallback(
    async (data: any, params: any) => {
      if (rouletteAlertDetail) {
        let jsonDataToSend: any = {
          alertId: rouletteAlertDetail.alertId,
          employeeid: rouletteAlertDetail.employeeId,
          note: data.note ?? "",
          isEmployeeTested:
            data.selectedOption ===
              RouletteEventOptionType.IS_CHECKED_AND_NOT_STOPPED ||
            data.selectedOption ===
              RouletteEventOptionType.IS_CHECKED_AND_STOPPED,
          isEmployeeStopped:
            data.selectedOption ===
            RouletteEventOptionType.IS_CHECKED_AND_STOPPED,
          didEmployeeRefusedTesting:
            data.selectedOption === RouletteEventOptionType.EMPLOYEE_REFUSED,
          isAlertOmitted: params === "omit",
        };

        setApproveOmmitRequestBody(JSON.stringify(jsonDataToSend));
        setIsFetching(true);
      }
    },
    [rouletteAlertDetail]
  );

  const handleRejectSubmit = useCallback(async () => {
    handleSubmit((data) => onFormSubmit(data, "omit"))();
  }, [handleSubmit, onFormSubmit]);

  return (
    <DialogStyled
      open={isOpen}
      onClose={closePopup}
      TransitionProps={{
        onExited: () => {
          reset();
        },
        onEnter: () => {
          setErrorMessage("");
          setIsFetching(false);
        },
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitleStyled title={t("approving_roulette_event")} />

      <DialogContentStyled>
        {!fetchingStateRouletteAlertDetail.isFetching && (
          <ApproveRouletteEventPopupContent
            control={control}
            setValue={setValue}
            handleSubmit={handleSubmit}
            onSubmit={onFormSubmit}
            rouletteAlertDetail={rouletteAlertDetail}
            fetchingStateRouletteAlertDetail={fetchingStateRouletteAlertDetail}
            fetchAgainRouletteAlertDetail={fetchAgainRouletteAlertDetail}
          />
        )}
      </DialogContentStyled>

      <DialogActionsStyled
        typConfirm="submit"
        formConfirm="submitForm"
        onConfirmColor="green"
        ifHideCancel={false}
        ifHideConfirm={false}
        ifHideReject={false}
        onConfirmText={t("approve")}
        onRejectText={t("omit")}
        onCancel={() => {
          closePopup();
        }}
        onReject={handleRejectSubmit}
        isLoading={isFetching}
        errorString={errorMessage}
      />
    </DialogStyled>
  );
};

export default ApproveRouletteEventPopup;
