import { useTranslation } from "react-i18next";
import { TopBanner } from "../../helpersComponents/TopBanner/topBanner";
import TableComponent from "../../helpersComponents/TableComponent/tableComponent";
import useFetchAndSetGET from "../../../hooks/fetchHooks/useFetchAndSetGET/useFetchAndSetGET";
import LoadingWrapper from "../../helpersComponents/LoadingWrapper/loadingWrapper";
import { Button, Tooltip } from "@material-ui/core";
import { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import ApproveRouletteEventPopup from "../Popups/ApproveRouletteEventPopup";

const HomePagePersonalControl = () => {
  const { t } = useTranslation();
  const columns = [
    { name: "employee", title: t("employee") },
    { name: "place", title: t("place") },
    { name: "eventTime", title: t("event_time"), type: "date_hours" },
    {
      name: "isEmployeeTestedStr",
      title: t("is_tested"),
    },
    {
      name: "isEmployeeStoppedStr",
      title: t("is_employee_stopped"),
    },

    {
      name: "didEmployeeRefusedTestingStr",
      title: t("did_employee_refused_testing"),
    },
    { name: "note", title: t("note") },
    { name: "createdBy", title: t("created") },
    { name: "createdAt", title: t("data_created"), type: "date_hours" },
    { name: "isApprovedStr", title: t("is_approved") },
    { name: "isAlertOmittedStr", title: t("is_omitted") },
    {
      name: "isLockedStr",
      title: t("is_locked"),
    },
  ];

  const modifyResponseCallback = useCallback(
    (responseData: any[]) => {
      const modified = responseData.map((row, index) => {
        return {
          ...row,
          id: row.alertId,
          employee: `${row.employeeFirstName} ${row.employeeLastName}`,
          isEmployeeTestedStr: row.isEmployeeTested ? t("yes") : t("no"),
          isEmployeeStoppedStr: row.isEmployeeStopped ? t("yes") : t("no"),

          didEmployeeRefusedTestingStr: row.didEmployeeRefusedTesting
            ? t("yes")
            : t("no"),

          isApprovedStr:
            !row.isAlertOmitted && row.isApproved ? t("yes") : t("no"),
          isAlertOmittedStr: row.isAlertOmitted ? t("yes") : t("no"),
          isLockedStr: row.isEmployeeLocked ? t("yes") : t("no"),
        };
      });
      return modified;
    },
    [t]
  );

  const [
    employeesToControl,
    fetchingStateEmployeesToControl,
    fetchAgainEmployeesToControl,
  ] = useFetchAndSetGET({
    path: `personal-control/employees-to-control`,
    startFetchOnInitial: true,
    modifyResponseCallback: modifyResponseCallback,
  });

  const [
    approveRouletteEventDialogParams,
    setApproveRouletteEventDialogParams,
  ] = useState<any>({ isOpen: false, rowId: null });

  const closePopup = useCallback(() => {
    setApproveRouletteEventDialogParams({ isOpen: false, rowId: null });
  }, []);

  const successCallback = useCallback(() => {
    fetchAgainEmployeesToControl();
  }, [fetchAgainEmployeesToControl]);

  return (
    <div>
      <TopBanner pathName={t("personal_control")} />
      <div className="flexAndCenter">
        <div>
          <LoadingWrapper
            isError={fetchingStateEmployeesToControl.isError}
            isLodadingProgress={fetchingStateEmployeesToControl.isFetching}
            setIfFetchAgain={fetchAgainEmployeesToControl}
          >
            {!fetchingStateEmployeesToControl.isFetching && (
              <TableComponent
                rows={employeesToControl || []}
                columns={columns}
                layoutSettingName="layoutPersonalControlEmployeesToControl"
                refreshingButton={{
                  ifShow: true,
                  onClick: () => {
                    fetchAgainEmployeesToControl();
                  },
                  onlyRefreshNow: true,
                }}
                actionsColumnUpdated={{
                  customButton1: {
                    ifShow: true,
                    customComponent: (row) => {
                      return (
                        <>
                          {!row.isApproved && (
                            <Tooltip title={t("approve")}>
                              <Button
                                onClick={() => {
                                  setApproveRouletteEventDialogParams({
                                    isOpen: true,
                                    rowId: row.alertId,
                                  });
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  style={{ fontSize: "20px", color: "green" }}
                                />
                              </Button>
                            </Tooltip>
                          )}
                        </>
                      );
                    },
                  },
                }}
              />
            )}
          </LoadingWrapper>
        </div>
      </div>

      <ApproveRouletteEventPopup
        isOpen={approveRouletteEventDialogParams.isOpen}
        rowId={approveRouletteEventDialogParams.rowId}
        successCallback={successCallback}
        closePopup={closePopup}
      />
    </div>
  );
};

export default HomePagePersonalControl;
